<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="deals_loading"
  >
    <template v-if="$can('read', 'catalog.deals')" slot="actions">
      <div slot="actions">
        <div class="field has-addons">
          <div class="is-flex mr-2">
            <b-button
              v-if="$device.mobile"
              size="is-small"
              rounded
              @click="showMobile()"
            >
              <b-icon icon="magnify" size="is-small" />
            </b-button>
            <b-input
              ref="search"
              v-model="headers[2].input"
              :size="$device.mobile ? 'is-small' : ''"
              :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
              :placeholder="$t('search')"
              icon="magnify"
              rounded
              @input="getDeals()"
              @blur="hideMobile()"
            />
          </div>
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
            append-to-body
          >
            <div slot="trigger">
              <b-button
                icon-left="dots-vertical"
                :size="$device.mobile ? 'is-small' : ''"
                type="is-default"
                rounded
              />
            </div>

            <b-dropdown-item
              v-if="$can('create', 'catalog.deals')"
              aria-role="listitem"
              @click="openForm(-1, {})"
            >
              <b-icon icon="plus" size="is-small" />
              {{ $t("new_deal") }}
            </b-dropdown-item>

            <b-dropdown-item separator />

            <b-dropdown-item
              aria-role="listitem"
              class="pr-3"
              @click="showColumnsTogle = !showColumnsTogle"
            >
              <b-icon icon="view-column" size="is-small" />
              {{ $tc("column", 2) }}
              <b-icon
                class="is-pulled-right"
                :icon="!showColumnsTogle ? 'chevron-down' : 'chevron-up'"
                size="is-small"
              />
            </b-dropdown-item>
            <template v-if="showColumnsTogle">
              <template v-for="item in headers">
                <b-dropdown-item
                  :key="item.key"
                  aria-role="listitem"
                  :disabled="item.disabled"
                  :focusable="false"
                  custom
                >
                  <b-switch
                    v-model="item.display"
                    :disabled="item.disabled"
                    type="is-success"
                  >
                    {{ item.text }}
                  </b-switch>
                </b-dropdown-item>
              </template>
            </template>
          </b-dropdown>
        </div>
      </div>
    </template>
    <Card v-if="$can('read', 'catalog.deals')" content-class="c-table">
      <div class="header-container">
        <div />
        <MonthPicker
          v-model="date_range"
          min-date="01/01/2020"
          :max-date="maxDate"
        />
      </div>
      <hr class="mt-0 mb-4" />

      <b-table
        :data="items"
        :loading="deals_loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        :row-class="
          (row, index) =>
            row.id == updated_deal
              ? 'bg-updated'
              : row.expired
              ? 'bg-deleted'
              : ''
        "
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value + '_' + head.display"
            :visible="head.display"
            :field="head.value"
            :label="head.text"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            v-bind="head"
            header-class="sticky-header"
          >
            <template v-if="head.searchable" slot="searchable">
              <template v-if="head.filter_type == 'date'">
                <b-datepicker
                  v-model="head.input"
                  rounded
                  :placeholder="placeholders.date.day"
                  size="is-small"
                  editable
                  :min-date="new Date('01/01/2013')"
                  @input="getDeals()"
                />
                <span class="custom-date-filter" @click="showDrop(head.value)">
                  <b-icon icon="filter" size="is-small" />
                </span>
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'is')"
                  >
                    {{ $t("is") }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'greater')"
                  >
                    {{ $t("is") }} &gt;
                  </b-dropdown-item>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="changeFilter(head.value, 'less')"
                  >
                    {{ $t("is") }} &lt;
                  </b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-else-if="head.filter_type == 'select'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  size="is-small"
                  :expanded="true"
                  @change="getDeals()"
                >
                  <template v-if="head.value === 'warehouse'">
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt[head.filter_id]"
                      :value="opt[head.filter_id]"
                    >
                      {{ opt.country.country + " - " + opt.currency.code }}
                    </option>
                  </template>
                  <template v-else>
                    <option
                      v-for="opt in head.filter_values"
                      :key="opt[head.filter_id] ? opt[head.filter_id] : opt"
                      :value="opt[head.filter_id] ? opt[head.filter_id] : opt"
                    >
                      {{ opt[head.filter_text] ? opt[head.filter_text] : opt }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  @change="getDeals()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  rounded
                  size="is-small"
                  icon-right="filter"
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getDeals"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <template v-if="head.value == 'id'" v-slot="props">
              <a
                v-if="
                  $can('read', 'catalog.deals') ||
                  $can('update', 'catalog.deals')
                "
                href="javascript:;"
                @click="openForm(props.index, props.row, 'read')"
              >
                {{ props.row.id }}
              </a>
              <span v-else>{{ props.row.id }}</span>
            </template>
            <template v-else-if="head.value == 'site_id'" v-slot="props">
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + props.row.site_id + '.svg'"
              />
            </template>

            <!-- Warehouse -->
            <template v-else-if="head.value === 'warehouse'" v-slot="props">
              <img
                height="18"
                width="18"
                :src="'/img/flags/' + props.row.warehouse.country.iso + '.svg'"
              />
            </template>

            <template v-else-if="head.value == 'brand'" v-slot="props">
              {{ props.row.brand ? props.row.brand.label : "-" }}
            </template>

            <template v-else-if="head.value == 'product'" v-slot="props">
              <span>
                {{ props.row.product ? props.row.product.label : "-" }}
                <span v-if="props.row.size" class="is-block">{{
                  props.row.size.label
                }}</span>
              </span>
            </template>

            <template v-else-if="head.value == 'created_at'" v-slot="props">
              <span>
                {{ props.row.created_at.formatted }}
                <span class="is-block has-text-grey-light">{{
                  props.row.created_at.time
                }}</span>
              </span>
            </template>

            <template v-else-if="head.value == 'starts_at'" v-slot="props">
              <span>
                {{ props.row.starts_at.formatted }}
                <span class="is-block has-text-grey-light">{{
                  props.row.starts_at.time
                }}</span>
              </span>
            </template>

            <template v-else-if="head.value == 'ends_at'" v-slot="props">
              <span>
                {{ props.row.ends_at.formatted }}
                <span class="is-block has-text-grey-light">{{
                  props.row.ends_at.time
                }}</span>
              </span>
            </template>

            <template v-else-if="head.value == 'buy'" v-slot="props">
              {{ (props.row.buy && props.row.buy.formatted) || "-" }}
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="
                    $can('read', 'catalog.deals') ||
                    $can('update', 'catalog.deals')
                  "
                  aria-role="listitem"
                  @click="openForm(props.index, props.row, 'read')"
                >
                  <b-icon icon="eye" size="is-small" />
                  {{ $t("view") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="$can('delete', 'catalog.deals')"
                  aria-role="listitem"
                  @click="confirmDeleting(props.index, props.row.id)"
                >
                  <b-icon icon="delete" type="is-danger" size="is-small" />
                  {{ $t("delete") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-else v-slot="props">
              <b-icon
                v-if="typeof props.row[head.value] === 'boolean'"
                :icon="props.row[head.value] ? 'check' : 'close'"
                :type="props.row[head.value] ? 'is-success' : 'is-danger'"
                custom-size="mdi-18px"
              />
              <template v-else>
                {{ props.row[head.value] }}
              </template>
            </template>
          </b-table-column>
        </template>
        <EmptyBlock slot="empty" icon="ticket-percent" />
      </b-table>

      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </Page>
</template>

<script>
import MonthPicker from "@/components/forms/elements/MonthPicker";
import moment from "moment";

export default {
  components: {
    MonthPicker,
  },
  data() {
    return {
      showColumnsTogle: false,
      perPage: 50,
      perPages: [50, 200, 500],
      sortingPriority: [{ field: "id", order: "asc" }],
      singleSorting: {},
      currentPage: 1,
      date_range: null,
      searchFocused: false,
      warehouse_id: null,
      deals_loading: true,
      updated_deal: null,
      search: "",
      total: 0,
      headers: [
        {
          text: this.$i18n.t("site"),
          value: "site_id",
          sortable: true,
          width: "80",
          searchable: true,
          filter_type: "select",
          filter_id: "id",
          filter_text: "label",
          filter_values: [],
          input: "",
          display: true,
        },
        {
          text: this.$i18n.tc("warehouse", 1),
          value: "warehouse",
          width: "80",
          sortable: true,
          searchable: true,
          filter_type: "select",
          filter_text: "country",
          filter_id: "id",
          filter_values: [],
          input: "",
          filter: "is",
          display: true,
        },
        {
          text: this.$i18n.t("id"),
          value: "id",
          sortable: true,
          searchable: true,
          width: "70",
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          text: this.$i18n.t("type"),
          value: "type",
          width: "100",
          sortable: true,
          searchable: true,
          filter_type: "select",
          filter_id: "id",
          filter_text: "label",
          filter_values: [],
          input: "",
          display: true,
        },
        {
          text: this.$i18n.tc("brand", 1),
          value: "brand",
          sortable: true,
          searchable: true,
          width: "150",
          input: "",
          filter: "contains",
          display: true,
        },
        {
          text: this.$i18n.tc("product", 1),
          value: "product",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
          display: true,
        },
        {
          text: this.$i18n.t("buy"),
          value: "buy",
          width: "70",
          sortable: true,
          searchable: true,
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          text: this.$i18n.t("get"),
          value: "get",
          width: "70",
          sortable: true,
          searchable: true,
          filter_type: "numeric",
          input: "",
          filter: "is",
          display: true,
        },
        {
          text: this.$i18n.t("combo"),
          value: "combo",
          width: "70",
          sortable: true,
          searchable: true,
          filter_type: "boolean",
          input: "",
          filter: "is",
          display: false,
        },
        {
          text: this.$i18n.t("created_at"),
          value: "created_at",
          sortable: true,
          width: "150",
          searchable: true,
          filter_type: "date",
          input: null,
          filter: "is",
          display: false,
        },
        {
          text: this.$i18n.t("starts_at"),
          value: "starts_at",
          sortable: true,
          width: "150",
          searchable: true,
          filter_type: "date",
          input: null,
          filter: "is",
          display: true,
        },
        {
          text: this.$i18n.t("expire_at"),
          value: "ends_at",
          sortable: true,
          width: "150",
          searchable: true,
          filter_type: "date",
          input: null,
          filter: "is",
          display: true,
        },
        {
          text: this.$i18n.t("actions"),
          value: "actions",
          centered: true,
          sortable: false,
          width: "80",
          display: true,
        },
      ],
      items: [],
      warehouses: [],
      sites: [],
      brands: [],
    };
  },
  computed: {
    maxDate() {
      return moment().add(1, "Y").format("YYYY/MM/DD");
    },
  },
  watch: {
    currentPage() {
      this.getDeals();
    },
    perPage() {
      this.getDeals();
    },
    date_range() {
      this.getDeals();
    },
    active() {
      this.getDeals();
    },
  },
  created() {
    this.updateTitle(this.$i18n.tc("deal", 2));
    this.$bus.$on("save-deal", ({ index, deal }) => {
      this.search = "";
      if (index != -1) {
        let dealIndex = this.items.findIndex((c) => c.id == deal.id);
        this.$set(this.items, dealIndex, deal);
      } else {
        for (let i = 0; i < deal.length; i++) {
          const d = deal[i];
          this.items.unshift(d);
        }
      }
      this.updated_deal = deal.id;
      setTimeout(() => {
        this.updated_deal = null;
      }, 2000);
    });
  },
  destroyed() {
    this.$bus.$off("save-deal");
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    closeDrop() {
      this.$refs["dropdown"].toggle();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getDeals();
    },
    confirmDeleting(index, id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteDeal(index, id),
      });
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getDeals();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getDeals();
    },
    getDeals() {
      this.items = [];

      this.warehouses = [];
      this.brands = [];
      this.sites = [];

      let api_params = "";

      api_params = `?page=${this.currentPage}&per_page=${this.perPage}`;

      if (this.date_range) {
        let month = moment(this.date_range).format("MM");
        let year = moment(this.date_range).format("YYYY");
        if (year && month) {
          if (!this.warehouse_id) api_params += `?year=${year}&month=${month}`;
          else api_params += `&year=${year}&month=${month}`;
        }
      }

      if (this.sortingPriority.length > 1) {
        let sortArr = [];
        for (let i = 0; i < this.sortingPriority.length; i++) {
          const sort = this.sortingPriority[i];
          sortArr.push(`${sort.field}:${sort.order}`);
        }
        api_params += `&sorting=${sortArr.join(",")}`;
      } else if (this.sortingPriority.length == 1) {
        api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
      } else if (this.singleSorting.field) {
        api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
      }

      let searchArr = [];
      for (let i = 0; i < this.headers.length; i++) {
        const value = this.headers[i];
        if (
          ["brand", "product"].includes(value.value) &&
          value.input &&
          value.input.length != 0 &&
          value.input.length < 3
        )
          return;
        else if (value.input || typeof value.input == "boolean") {
          let obj = {
            id: value.value,
            selected:
              value.value == "ends_at" ||
              value.value == "starts_at" ||
              value.value == "created_at"
                ? moment(value.input).format("YYYY-MM-DD")
                : value.input,
          };
          if (value.filter) obj.operator = value.filter;
          searchArr.push(obj);
        }
      }
      if (searchArr.length)
        api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.deals_loading = true;

      this.$axios
        .get("deals" + api_params)
        .then((res) => {
          let siteIdIndex = this.headers.findIndex((h) => h.value == "site_id");
          if (this.headers[siteIdIndex].filter_values.length == 0)
            this.headers[siteIdIndex].filter_values = res.data.filters.sites;

          let typeIndex = this.headers.findIndex((h) => h.value == "type");
          if (this.headers[typeIndex].filter_values.length == 0)
            this.headers[typeIndex].filter_values =
              res.data.filters.types || [];

          let warehouse_index = this.headers.findIndex(
            (h) => h.value == "warehouse"
          );
          if (this.headers[warehouse_index].filter_values.length == 0)
            this.headers[warehouse_index].filter_values =
              res.data.filters.warehouses;

          this.items = res.data.list;
          this.sites = res.data.filters.sites;
          this.brands = res.data.filters.brands;
          if (this.warehouses.length == 0)
            this.warehouses = res.data.filters.warehouses;
          if (!this.warehouse_id) this.warehouse_id = this.warehouses[0].id;
          this.total = res.data.pagination.total;
        })
        .catch((e) => {
          const code = parseInt(e.response && e.response.status);
          if (code) {
            if (code === 404) {
              this.items = [];
              this.total = 0;
            } else {
              this.clientError(e);
            }
          }
        })
        .finally(() => (this.deals_loading = false));
    },
    deleteDeal(index, deal_id) {
      this.$axios
        .delete("deals/" + deal_id)
        .then(() => {
          this.items.splice(index, 1);
        })
        .catch((e) => this.$root.clientError(e));
    },
    openForm(index, deal, action = "cu") {
      let deal_form = { ...deal };
      if (index === -1) {
        deal_form = {
          ...deal,
          min_amount: { value: null },
          amount: { value: null },
          currency: { code: null },
        };
      }
      let options = {
        key: Math.random(),
        index: index,
        deal: deal_form,
        sites: this.sites,
        warehouses: this.warehouses,
        brands: this.brands,
        user_id: null,
        action: action,
        from: "ecommerce",
      };
      this.openDrawer("dealForm", options);
    },
    openSlides() {
      let options = {
        key: Math.random(),
        sites: this.sites,
        warehouses: this.warehouses,
        from: "ecommerce",
      };
      this.openDrawer("slideForm", options);
    },
  },
};
</script>
